<template>
	<div id="cpmLandType">
		<div class="el-content">
			<div class="kd-pacel">搜索参数</div>
			<a-space>
				<a-input-search placeholder="名称" @search="getTypeList(1,info.limit)" v-model:value="search.key" style="width: 400px;">
					<a-button><a-icon type="search" />搜索</a-button>
				</a-input-search>
				<a-button type="primary" v-has="{action:'wxapp_land_add',plat:isShop}" @click="addType(0)">
					<i class="ri-add-line ri-lg ri-top"></i>新增分类
				</a-button>
				<a-button type="danger" v-has="{action:'wxapp_land_type_del',plat:isShop}" @click="deleteLandType(pks)">
					<i class="ri-delete-bin-5-line ri-lg ri-top"></i>批量删除
				</a-button>
			</a-space>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :data-source="info.list" :pagination="false" row-key="id"  :columns="[
				{dataIndex:'id',title:'ID'},
				{dataIndex:'name',title:'分类名称'},
				{dataIndex:'status',title:'是否显示',slots: { customRender: 'status' }},
				{dataIndex:'rank',title:'排序'},
				{dataIndex:'update_time',title:'最后更新时间'},
				{dataIndex:'action',title:'操作',slots: { customRender: 'action' }},
			]" :row-selection="{ selectedRowKeys: pks, onChange:(e)=>{pks = e}  }">
				<template #status="{record}">
					<a-tag :color="record.is_show ==1 ? '#00CC66' : '#999'">
						{{record.is_show ==1 ? '显示' : '不显示'}}
					</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<kd-button 
							type="primary" 
							icon="ri-edit-2-line" 
							title="编辑" 
							@click="addType(record)" 
							v-has="{action:'wxapp_land_add',plat:isShop}">
						</kd-button>
						<kd-button 
							type="danger" 
							icon="ri-delete-bin-5-line" 
							title="删除" 
							@click="deleteLandType(record.id)" 
							v-has="{action:'wxapp_land_type_del',plat:isShop}">
						</kd-button>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
				    show-size-changer
				    :default-current="info.page"
				    :total="info.count"
					:default-page-size="info.limit"
				    @showSizeChange="(p,e)=>{getTypeList(info.page,e)}"
					@change="(e)=>{getTypeList(e,info.limit)}"
				/>
			</div>
		</div>
		<a-modal title="添加分类" :visible="showEdit" :width="600" @ok="saveLandType" @cancel="showEdit = false">
			<a-form :label-col="{span:4}" :wrapper-col="{span:16}">
				<a-form-item label="分类名称" required>
					<a-input v-model:value="form.name"></a-input>
				</a-form-item>
				<a-form-item label="排序">
					<a-input v-model:value="form.rank"></a-input>
				</a-form-item>
			</a-form>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import landModel from '@/api/land.js'
import common from '@/api/common.js'
export default{
	name:'page-land-type',
	props:{
		isShop:{
			type:Number,
			value:0
		}
	},
	setup(){
		const _d = reactive({
			search:{key:""},
			info:{
				list:[],
				page:1,
				count:0,
				limit:10,
			},
			pks:[],
			showEdit:false,
			form:null
		})
		getTypeList(1,_d.info.limit)
		function getTypeList(page,limit){
			landModel.getLandCategroy(page,limit,_d.search,res=>{
				_d.info.list = res.list
				_d.info.count = res.count
			})
		}
		function addType(row){
			_d.form = {
				id:row ? row.id : 0,
				name:row ? row.name :"",
				rank:row ? row.rank :99,
			}
			_d.showEdit = true
		}
		function saveLandType(){
			landModel.addOrEditCategory(_d.form,()=>{
				_d.showEdit = false
				getTypeList(_d.info.page,_d.info.limit)
			})
		}
		const deleteLandType = (id)=>common.deleteDataList(id,2,"确认删除该土地分类吗?").then(res=>{
			getTypeList(_d.info.page,_d.info.limit)
		})
		return{
			...toRefs(_d),
			getTypeList,
			deleteLandType,
			addType,
			saveLandType
		}
	},
}
</script>
<style>
</style>
